import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import CallIcon from "@material-ui/icons/Call";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import "../MapContainer.css";
const LocationCard = React.lazy(() => import("./LocationCard"));

const MapContainer = ({ mapData, getLocation }) => {
  const [selected, setSelected] = useState({});
  const [currentPosition, setCurrentPosition] = useState({});
  // phoenix
  const defaultCenter = {
    lat: 33.448376,
    lng: -112.074036,
  };

  const onSelect = (item) => {
    setSelected(item);
  };

  const success = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    const currentPosition = {
      lat: latitude,
      lng: longitude,
    };
    setCurrentPosition(currentPosition);
  };

  const mapStyles = () => {
    return {
      marginTop: "10px",
      height: "80vh",
      width: "80%",
    };
  };

  useEffect(() => {
    if (mapData) {
      setCurrentPosition(mapData.currentPosition);
    } else {
      navigator.geolocation.getCurrentPosition(success);
    }
  }, [mapData, currentPosition]);

  return (
    <>
      <React.Suspense fallback={<div>Loading...</div>}>
        <LoadScript
          id="script-loader"
          googleMapsApiKey="AIzaSyBv7-gzdiY7DKviflc_x737O2TwvvAPbWg"
        >
          <div
            style={{
              display: "flex",
              placeContent: "center",
              padding: "0 10em 0 10em",
            }}
          >
            <GoogleMap
              id="script-loader"
              mapContainerStyle={mapStyles()}
              draggable={true}
              zoom={11}
              center={currentPosition.lat ? currentPosition : defaultCenter}
            >
              {mapData.agencyList.length > 0
                ? mapData.agencyList.map((item) => (
                    <Marker
                      key={item.Id}
                      position={{ lat: item.latitude, lng: item.longitude }}
                      onClick={() => onSelect(item)}
                    />
                  ))
                : null}
              )
              {selected.name ? (
                <InfoWindow
                  position={{ lat: selected.latitude, lng: selected.longitude }}
                  onCloseClick={() => setSelected({})}
                >
                  <div
                    className="infowindow"
                    style={{
                      border: "none",
                      marginRight: "5px",
                      backgroundColor: "#0d3693",
                    }}
                  >
                    <p
                      style={{
                        borderBottom: "1px solid white",
                        width: "100%",
                        paddingBottom: "17px",
                        marginBottom: 0,
                        color: "white",
                      }}
                    >
                      <b style={{ fontSize: "15px" }}>{selected.name}</b>
                    </p>
                    {/* <img src={selected.image} className="small-image" alt="rental"/> */}
                    <p
                      style={{
                        fontWeight: 500,
                        marginBottom: 0,
                        color: "white",
                      }}
                    >
                      <LocationCityIcon
                        style={{
                          marginRight: "5px",
                          verticalAlign: "middle",
                        }}
                      >
                        Location Icon
                      </LocationCityIcon>
                      {selected.formattedAddress}
                    </p>
                    <p style={{ fontWeight: 500, color: "white" }}>
                      <CallIcon
                        style={{ marginRight: "5px", verticalAlign: "middle" }}
                      >
                        Phone Icon
                      </CallIcon>
                      {selected.phone}
                    </p>
                  </div>
                </InfoWindow>
              ) : null}
            </GoogleMap>
            {mapData.agencyList.length > 0 ? (
              <div
                style={{
                  height: "47.5em",
                  overflowY: "scroll",
                  width: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                  }}
                >
                  {mapData.agencyList.map((item) => (
                    <LocationCard
                      location={item}
                      onSelect={onSelect}
                      selected={selected}
                    />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </LoadScript>
      </React.Suspense>
    </>
  );
};

export default MapContainer;
