import React, { useState } from 'react';
import Button from './Button';
// import Select from "@material-ui/core/Select";
import {
  Box,
  styled,
  MenuItem,
  TextField,
  InputLabel,
  Select,
  FormControl,
} from '@material-ui/core';
import '../Filter.css';
import SearchIcon from '@material-ui/icons/Search';
import logo_phcn_final from '../assets/providerFinderTransparent.png';
import ProviderFinderText from '../assets/TransparentSheet.png';

const MySearchIcon = styled(SearchIcon)({
  color: 'white',
});

export default function Filter({ clickHandler }) {
  const [filter, setFilter] = useState({
    address: '',
    usState: '',
  });

  const changeHandler = (evt) => {
    const value = evt.target.value;
    setFilter({
      ...filter,
      [evt.target.name]: value,
    });
  };

  const enterPress = (e) => {
    if (e.keyCode === 13) {
      clickHandler({ filter });
    }
  };

  return (
    <div
      style={{
        width: '100%',
        background: 'rgb(13, 54, 147)',
        display: 'flex',
        justifyContent: 'space-evenly',
      }}
    >
      <a href='https://prohcn.com/'>
        <img
          src={logo_phcn_final}
          alt='phcn-logo'
          style={{
            float: 'left',
            margin: '10px',
            padding: '4px',
            height: '90px',
          }}
        />
      </a>
      <>
        <img
          src={ProviderFinderText}
          alt='provider-finder-title'
          style={{ height: '25px', alignSelf: 'center' }}
        />
        <Box
          display='flex'
          flexWrap='nowrap'
          p={2}
          m={1}
          sx={{ maxWidth: 1500, placeContent: 'center' }}
        >
          <MySearchIcon
            style={{
              alignSelf: 'flex-end',
              marginRight: '8px',
              marginBottom: '10px',
            }}
          >
            Search Icon
          </MySearchIcon>
          <TextField
            style={{ width: 450 }}
            id='address'
            name='address'
            label='Enter an address, city or postal code'
            type='search'
            margin='normal'
            onChange={(event) => changeHandler(event)}
            onKeyDown={enterPress}
            InputProps={{
              style: {
                color: 'white',
              },
              classes: { underline: 'white' },
            }}
            InputLabelProps={{
              style: {
                color: 'white',
              },
            }}
          />
          <div
            style={{
              width: '60px',
              placeSelf: 'center',
              color: 'white',
              marginTop: '20px',
            }}
          >
            or
          </div>
          <FormControl style={{ alignSelf: 'center', marginTop: 'inherit' }}>
            <InputLabel
              id='state-select-provider-finder'
              style={{ color: 'white' }}
            >
              State
            </InputLabel>
            <Select
              labelId='state-select-provider-finder'
              style={{
                width: 120,
                color: 'white',
              }}
              value={filter.usState}
              onChange={(event) => changeHandler(event)}
              inputProps={{
                name: 'usState',
                id: 'usState',
              }}
            >
              <MenuItem value=''> </MenuItem>
              <MenuItem value={'AZ'}>Arizona</MenuItem>
              <MenuItem value={'CO'}>Colorado</MenuItem>
              <MenuItem value={'NV'}>Nevada</MenuItem>
              <MenuItem value={'NM'}>New Mexico</MenuItem>
            </Select>
          </FormControl>
          <Button
            style={{ top: '7px' }}
            p={1}
            m={2}
            variant='contained'
            color='primary'
            onClick={(event) => clickHandler({ filter })}
          >
            Search
          </Button>
        </Box>
      </>
    </div>
  );
}
