import axios from "axios";
import HttpsProxyAgent from "https-proxy-agent";
export async function geoCodeAddress(filter) {
  if (!filter) {
    return;
  }
  // set up proxy (prob a better way to do this)
  const agent = new HttpsProxyAgent({
    host: `${process.env.REACT_APP_API_PROXY_HOST}`,
    port: `${process.env.REACT_APP_API_PROXY_PORT}`,
  });
  const maxios = axios.create({ httpsAgent: agent });
  // formats incoming input to be used as URL
  const useUSState = filter.address === "";
  
  var encodedAddress = "";
  // when they use state, use this for centering the map
  if (useUSState) {
    if (filter.usState ===""){
      filter.usState ="AZ";
    }
    switch (filter.usState)
    {
      case 'NV':
        encodedAddress ="Las%20Vegas,+NV,+USA";
        break;
      case 'AZ':
        encodedAddress ="Phoenix,+AZ,+USA";
        break;
      case 'CO':
          encodedAddress ="Denver,+CO,+USA";
          break;        
      case 'NM':
        encodedAddress ="Albuquerque,+NM,+USA";
        break;
      default:
        encodedAddress ="Phoenix,+AZ,+USA";
    }
 } else {
    encodedAddress = encodeURI(filter.address);
  }
  var agencyList=[];
  try {
    const locationRes = await maxios.get(`/api/location/${encodedAddress}`)
    if (locationRes.status !==200){
       return{
        "currentPosition": {"lat":0, "lng":0},
        agencyList,
      }; 
    }
      // creates json object
      const currentPosition = locationRes.data.currentPosition;
      let agencyUrl = useUSState
        ? `/api/agencies/${filter.usState}`
        : `/api/agenciesloc/${currentPosition.lat}/${currentPosition.lng}`;

      const agencyResponse = await maxios.get(agencyUrl).catch(function (error) {
        if (error.response) {
          console.error("ERROR" + error.response);
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
        }
        });
        if (agencyResponse.status ===200){
          agencyList = agencyResponse.data || agencyResponse;
        }
      return {
        currentPosition,
        agencyList,
      };
      
  } catch (e) {
    // returns bad request error if something goes wrong
    console.error("ERROR" );
    console.error(e);
  }
}
