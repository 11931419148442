import React, { useState } from 'react';
import { geoCodeAddress } from '../api/geocoding';
import Filter from './Filter';
import MapContainer from './MapContainer';
import { Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  popoverStyle: {
    padding: '1.3em',
    background: 'darkred',
    color: 'white',
    fontWeight: 700,
  },
});

export default function Landing() {
  const classes = useStyles();
  const [data, setData] = useState({
    currentPosition: { lat: 0, lng: 0 },
    agencyList: [],
  });
  const [popoverIsOpen, setPopoverIsOpen] = useState({
    isOpen: false,
    message: '',
  });

  const popoverClose = () => {
    setPopoverIsOpen({
      isOpen: false,
      message: '',
    });
  };

  const clickHandler = async (evt) => {
    const { filter } = evt;

    if (!filter.address && filter.usState === '') {
      setData(null);
      return;
    }

    try {
      const response = await geoCodeAddress(filter);
      console.log(response, 'response');

      if (!response || response.agencyList.length === 0) {
        setPopoverIsOpen({
          isOpen: true,
          message: 'No records found.',
        });
      } else {
        setData(response);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div id='body' style={{ display: 'block', textAlign: '-webkit-center' }}>
      <Popover
        classes={{
          paper: classes.popoverStyle,
        }}
        open={popoverIsOpen.isOpen}
        onClose={popoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        {popoverIsOpen.message}
      </Popover>
      <Filter clickHandler={clickHandler} />
      <MapContainer mapData={data} loading={true} />
      <footer style={{ fontSize: '15px', color: '#0d3693' }}>
        <p>Copyright 2024 - powered by tango</p>
      </footer>
    </div>
  );
}
